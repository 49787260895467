import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";
import moment from 'moment';
import { Link } from 'react-router-dom';
// utilities
import Constants from '../Utilities/Constants';
import withRouter from '../Utilities/withRouter'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import '../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../node_modules/react-date-range/dist/theme/default.css'; // theme css file
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


class EventList extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            country_code: '+91',
            ccode: "+91",
            email: "",
            mobileNo: "",
            remark: "",
            assignRole: "",
            firstName: "",
            lastName: "",
            country: "",
            state: "",
            city: "",
            RegistrationLinkToCopy: Constants.CurrentUrl + "EventRegister/",
            EventAttendantLinkToCopy: Constants.CurrentUrl + "EventAttendant/",
            QRCodeValue: "ABCD12345",
            locations: [],
            counts: {
                totalUsers: 0,
                mainApp: 0,
                gbnApp: 0,
                sangathanApp: 0,
                eventUsers: 0,
                locationUsers: 0,
            },
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            eventList: [],
            showDatePicker: false,
        };

    }


    async componentDidMount() {


        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        let heading = {
            title: 'Event',
            subTitle: ['List Event']
        };
        localStorage.setItem('heading', JSON.stringify(heading));

        this.getCounts()

        // Deprecated 
        // this.getLocations()

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    getLocations = () => {

        let urlPath = "DynamicPage/entry-locations/"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    this.setState({ locations: responseData.data, });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }


    getCounts = (filter = false) => {
        // getReportDashboard
        this.setState({ loading: true });
        let urlPath = "appmanager/dashboard/"

        const startDate = this.state.selectionRange.startDate;
        const endDate = this.state.selectionRange.endDate;

        // if(filter) {

        // }


        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filter: filter,
                startDate: startDate,
                endDate: endDate
            })
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    // {
                    //     "status": true,
                    //     "data": {
                    //         "totalUsers": 2202,
                    //         "mainApp": 1929,
                    //         "gbnApp": 10,
                    //         "sangathanApp": 155,
                    //         "eventUsers": 858
                    //     }
                    // }
                    this.setState({
                        counts: {
                            totalUsers: responseData.data.totalUsers,
                            mainApp: responseData.data.mainApp,
                            gbnApp: responseData.data.gbnApp,
                            sangathanApp: responseData.data.sangathanApp,
                            eventUsers: responseData.data.eventUsers,
                            locationUsers: responseData.data.totalLocationsUsers,
                        },
                        locations: responseData.data.locations,
                        eventList: responseData.data.eventList
                    });

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                this.setState({ loading: false });
            });

    }




    applyFilter = () => {

        const startDate = new Date(this.state.selectionRange.startDate);
        const endDate = new Date(this.state.selectionRange.endDate);
        let filter = false;
        if (startDate < endDate) {
            filter = true
            // console.log(`${startDate} is earlier than ${endDate}`);
        } else if (startDate > endDate) {
            filter = true
            // console.log(`${startDate} is later than ${endDate}`);
        } else {
            filter = false
            console.log(`${startDate} is the same as ${endDate}`);
        }


        // console.log("Date ",this.state.selectionRange,filter,startDate,endDate)
        this.setState({ showDatePicker: false })
        this.getCounts(filter)
    }





    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    handleSelect = (ranges) => {
        console.log(ranges);
        this.setState({ selectionRange: ranges.selection })
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }
    handleDeleteClick = (e, event) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this event?");
        if (confirmDelete) {
            // Delete the item or perform the desired action
            this.setState({ deleteItemId: event._id, })
            this.onCheckDelete(e, event._id)

            console.log("Item deleted.");
        } else {
            console.log("Deletion canceled.");
        }
    };
    onCheckDelete = (e, event) => {
        e.preventDefault()

        // const { signOut } = this.context;

        const { token } = this.state;


        this.setState({ loading: true, itemDeleteLoading: true })

        let urlPath = "event/DeleteEvent/" + event;


        // this.setState({ loading: false });
        // return


        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status) {
                    alert(
                        "Event Removed Successfully.")
                    this.getCounts()

                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
                return this.setState({ loading: false, deleteItemId: null, })
            });
    }

    render() {

        const columns = [

            {
                name: 'Event Name',
                width: "23%",
                sortable: true,
                selector: row => row.eventName
            },
            {
                name: 'Reg. Date',
                width: "13%",
                sortable: true,
                selector: row => [moment(row.registrationStartDate).format("DD MMM YYYY"), <br />, " to ", <br />, moment(row.registrationEndDate).format("DD MMM YYYY")]
            },
            {
                name: 'Event Date',
                width: "13%",
                sortable: true,
                selector: row => [moment(row.eventStartDate).format("DD MMM YYYY"), <br />, " to ", <br />, moment(row.eventEndDate).format("DD MMM YYYY")]
            },
            {
                name: 'Location',
                width: "13%",
                sortable: true,
                selector: row => row.city != undefined ? [row.city, ",", <br />, row.state, ",", <br />, row.country] : ""
            },
            {
                name: 'Reg. Link',
                width: "10%",
                sortable: true,
                selector: row => <a data-toggle="tooltip" title='Copy Registration Link' className="btn btn-warning btn-sm m-1 copylink " onClick={() => { navigator.clipboard.writeText(this.state.RegistrationLinkToCopy + `${row._id}`) }}>
                    <span className=" menu-icon fas fa fa-clone"></span></a>
            },
            {
                name: 'Attnd. Link',
                width: "10%",
                sortable: true,
                selector: row =>
                //  <Link to={`/EventAttendant/${row._id}`} className="btn btn-warning btn-sm m-1 " >
                //                 <span className="menu-text" style={{ color: 'white' }}>
                //                     <span className=" menu-icon fas fa fa-eye" style={{ color: 'white' }}>
                //                     </span>
                //                 </span>
                //             </Link>
                
                <a data-toggle="tooltip" title='Copy Event Attendant Link' className="btn btn-warning btn-sm m-1 copylink" onClick={() => { navigator.clipboard.writeText(this.state.EventAttendantLinkToCopy + `${row._id}`) }}>
                    <span className=" menu-icon fas fa fa-clone"></span> </a>
            },
            {
                name: 'Action',
                width: "18%",
                sortable: false,
                selector: row => [

                    <Link to={`/EventUsers/${row._id}`} className="btn btn-info btn-sm m-1 " >
                        <span className="menu-text" style={{ color: 'white' }}>
                            <span className=" menu-icon fas fa fa-eye" style={{ color: 'white' }}>
                            </span>
                        </span>
                    </Link>,
                    <a href={`/Event/${row._id}`} data-toggle="tooltip" title='Edit Event' className="btn btn-warning btn-sm m-1 ">
                        <span className="menu-text" style={{ color: 'white' }}>
                            <span className=" menu-icon fas fa fa-pen" style={{ color: 'white' }}>
                            </span>
                        </span>
                    </a>,

                    <button type="button" className="btn btn-danger btn-sm m-1" onClick={(e) => { this.handleDeleteClick(e, row) }}>
                        <span className=" menu-icon fas fa fa-trash" style={{ color: 'white' }}>
                        </span></button>
                ]
            }
        ];

        const tableData = {
            columns,
            data: this.state.eventList,
        };

        const { dropdownData, showDatePicker, selectionRange } = this.state;

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" >
                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title" >Event List</h3>

                                            <Link to={'/Event'} className="btn btn-danger btn-sm h5 m-3 mb-5 mr-2" >
                                                <span className="menu-text" style={{ color: 'white' }}>Add Event</span>
                                            </Link>
                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12 text-center'>
                                                {this.state.eventList.length > 0 ?
                                                    <DataTableExtensions
                                                        {...tableData}
                                                        export={false}
                                                        print={false}
                                                    >
                                                        <DataTable
                                                            noHeader
                                                            defaultSortField="id"
                                                            defaultSortAsc={false}
                                                            pagination
                                                            highlightOnHover
                                                            paginationPerPage={30}
                                                        />
                                                    </DataTableExtensions>
                                                    // <table className="table table-head-custom" id="kt_datatable1">
                                                    //     <thead>
                                                    //         <tr>
                                                    //             <th>Sr No</th>
                                                    //             <th>Event Name</th>
                                                    //             <th className='text-center'>Registration Date</th>
                                                    //             <th className='text-center'>Event Date</th>
                                                    //             <th>Location</th>
                                                    //             <th>Reg. Link</th>
                                                    //             <th>Attnd. Link</th>
                                                    //             <th  className='text-center'>Action</th>
                                                    //         </tr>
                                                    //     </thead>
                                                    //     <tbody>
                                                    //         {

                                                    //             this.state.eventList.map((event, i) => {
                                                    //                 return (
                                                    //                     <tr>
                                                    //                         <td style={{ width: '10%', textAlign: 'center' }}>{i + 1}</td>
                                                    //                         <td style={{ width: '20%' }}>

                                                    //                             <span className="form-text">{event.eventName}</span>

                                                    //                         </td>
                                                    //                         <td className='text-center' style={{ width: '20%' }}>
                                                    //                             {moment(event.registrationStartDate).format("DD MMM YYYY")} <br/> to <br/> {moment(event.registrationEndDate).format("DD MMM YYYY")}
                                                    //                             {/* <span className="form-text"></span> - <span className="form-text"></span> */}
                                                    //                         </td>
                                                    //                         <td className='text-center' style={{ width: '20%' }}>
                                                    //                             {moment(event.eventStartDate).format("DD MMM YYYY")} <br/>to<br/> {moment(event.eventEndDate).format("DD MMM YYYY")}
                                                    //                             {/* <span className="form-text"></span> - <span className="form-text"></span> */}
                                                    //                         </td>
                                                    //                         <td className='text-center' style={{ width: '10%' }}>
                                                    //                         {event.city!=null ? <span>{event.city}, {event.state}, {event.country}</span>  : null}   
                                                    //                         </td>

                                                    //                         <td>
                                                    //                         <a data-toggle="tooltip" title='Copy Registration Link'  className="btn btn-warning btn-sm m-1 copylink " onClick={() => {navigator.clipboard.writeText(this.state.RegistrationLinkToCopy+`${event._id}`)}}>
                                                    //                         <span className=" menu-icon fas fa fa-clone"></span></a>

                                                    //                         </td>
                                                    //                         <td>
                                                    //                         <a data-toggle="tooltip" title='Copy Event Attendant Link'  className="btn btn-warning btn-sm m-1 copylink" onClick={() => {navigator.clipboard.writeText(this.state.EventAttendantLinkToCopy+`${event._id}`)}}>
                                                    //                         <span className=" menu-icon fas fa fa-clone"></span> </a>

                                                    //                         </td>
                                                    //                         <td style={{ width: '20%' }} > 
                                                    //                         <a href={`/Event/${event._id}`} data-toggle="tooltip" title='Edit Event' className="btn btn-warning btn-sm m-1 ">
                                                    //                                 <span className="menu-text" style={{ color: 'white' }}>
                                                    //                                     <span className=" menu-icon fas fa fa-pen" style={{ color: 'white' }}>
                                                    //                                     </span>
                                                    //                                 </span>
                                                    //                             </a>
                                                    //                            <Link to={`/EventUsers/${event._id}`} className="btn btn-danger btn-sm m-1 " >
                                                    //                                 <span className="menu-text" style={{ color: 'white' }}>
                                                    //                                     <span className=" menu-icon fas fa fa-eye" style={{ color: 'white' }}>
                                                    //                                     </span>
                                                    //                                 </span>
                                                    //                             </Link>
                                                    //                             {event.count==0?
                                                    //                             <button type="button" className="btn btn-danger btn-sm m-1" onClick={(e) => { this.handleDeleteClick(e, event) }}>
                                                    //                                 <span className=" menu-icon fas fa fa-trash" style={{ color: 'white' }}>
                                                    //                                 </span></button>
                                                    //            :'' }
                                                    //                         </td>
                                                    //                     </tr>)
                                                    //             })
                                                    //         }
                                                    //     </tbody>
                                                    // </table>

                                                    :
                                                    <div className="col-md-12" style={{ marginTop: 20 }}>
                                                        <div className="col-md-12" style={{ justifyContent: 'center', alignItems: 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius: 5, padding: '15px 20px', display: 'flex' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                                <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>No Events</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(EventList);