import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import { Link, Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import axios from 'axios';
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { appendScript } from '../Utilities/appendScript';
import moment from 'moment';
import QRCode from "react-qr-code";
import withRouter from '../Utilities/withRouter'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const BootstrapInput = styled(TextField)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

class UsersDetails extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.recaptchaRef = React.createRef();
        this.googlePlaceInputRef = React.createRef();

        const { id,Type } = props.params;
        // const location = match.params.location;

        let enableLocationChange = false


        // // console.log("PARAMS LOCATION ",location,enableLocationChange)

        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            OrganizationID: Constants.defaultOrganizationId,
            Locations: [],
            RoleList: [],
            SangathanList: [],
            isRedirect: false,
            sangathanId: null,
            country_code: '+91',
            dropdownData: [],
            interestData: [
            ],
            ccode: "+91",
            city: "",
            mobileNo: "",
            Profession: "",
            OtherProfession: "",
            selectedLocation: "",
            enableLocationChange: enableLocationChange,
            firstName: null,
            lastName: null,
            NickName: null,
            description: null,
            birthDate: null,
            age: null,
            countries: [],
            stateList: [],
            districtList: [],
            CityList: [],
            VillageList: [],
            _id: id,
            selectedCity: "",
            selectedCountry: "",
            selectedVillage: "",
            selectedDistrict: "",
            selectedState: "",

            cityCode: "",
            districtCode: "",
            stateCode: "",
            countryDataCode: "",
            villageCode: "",

            selectedUserInterest: [],
            selectedSangathan: {
                label: null,
                name: null,
                _id: null,
                options: [],
            },
            token: 'Bearer SECRET',
            city: null,
            district: null,
            state: null,
            country: null,
            village: "",
            postal_code: null,
            area: null,
            street_address: "",
            addressComponent: null,
            defaultAddress: "",
            isNumberVerified: false,
            optionalFieldAllow: true,
            Type:Type.replace('_','/'),
            info: { type: "info", msg: "" }
        };
    }

    async componentDidMount() {

        let heading = {
            title: 'User',
            subTitle: ['User Details']
        };
        localStorage.setItem('heading', JSON.stringify(heading));
        await appendScript('/assets/js/pages/crud/file-upload/image-input.js');

        await this.onUserInvite()

    }


    _getPrepareCountryListCall = () => {

        // // console.log(`${Constants.THIRD_PARTY_API_URL}getaccesstoken/`,)
        axios({
            url: `${Constants.THIRD_PARTY_API_URL}getaccesstoken/`,
            method: 'GET',
            headers: {
                "api-token": Constants.THIRD_PARTY_TOKEN,
                "user-email": "ashish.gajjar@xpertnest.com",
                "Accept": "application/json"
            }
        })
            .then((response) => {
                const responseData = (response.data || {});
                // // console.log("responseData =>",JSON.stringify(response))
                if (response?.status == '200') {

                    this.setState({ third_party_token: responseData.auth_token })
                    this._getCountryList(responseData.auth_token)

                    this.setState({ selectedCity: "", selectedState: "", selectedCountry: "", stateList: [], countries: [], CityList: [] })
                }

            }).catch((error) => {
                // // console.log('error', error)
            });

    }
    onSelectState = async (item) => {
        await this.setState({ selectedState: item })

        let CityName = item
        // // console.log("CityName===>", CityName)

        axios({
            url: `${Constants.THIRD_PARTY_API_URL}cities/${CityName}`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + this.state.third_party_token,
                "Accept": "application/json"
            }
        })
            .then((response) => {
                // // console.log(response.status)
                const responseData = (response.data || {});
                if (response?.status == '200' && responseData.length > 0) {
                    const cityList = responseData.map((data, index) => {

                        // return data.city_name
                        return { ...data, label: data.city_name }
                        // return { ...data, Name: data.city_name, Value: data.city_name }
                    })
                    this.setState({ CityList: cityList })
                    // // console.log("CityList===>", cityList)

                }
                // // console.log("responseData =>", responseData)
            }).catch((error) => {
                // // console.log('error', error)
            });


    }
    onGetLocationsList = async () => {
        await this.setState({ loading: true })

        const { token, user, searchText } = this.state;

        let state = user
        let urlPath = "DynamicPage/entry-locations/"

        // // console.log("List Role =>", Constants.url + urlPath)

        // // // console.log("TOKEN ",token)
        // return 

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;
                    let RoleList = [];

                    const LocationsList = data.filter(item => item.status === "true").map((item) => {
                        return { ...item, Name: item.name, id: item.place_id, Value: item.name }
                    })
                    // // // console.log("DL ",LocationsList)

                    if (LocationsList.length > 0 && !this.state.enableLocationChange) {
                        // setSelectedLocation(LocationsList[0])
                        this.setState({ selectedLocation: LocationsList[0].place_id, defaultAddress: this.getFormattedAddressComponentData(LocationsList[0]?.addressComponent || null), refreshInput: true })
                    }

                    this.setState({ Locations: LocationsList, refreshInput: false })
                    await this.onGetCountryList();
                    // this.setState({ Locations : LocationsList})

                    //   await this.setState({ RoleList, loading: true })

                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                } else {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }

    onUserInvite = async (e) => {

        this.setState({ loading: true })


        let urlPath = "GetUserDetails/" + this.state._id;

        // console.log(Constants.AUTH_URL + urlPath, token, Body)
        // this.setState({ loading : false })
        // return

        fetch(Constants.AUTH_URL + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then((responseData) => {


                if (responseData.status == true) {
                    var user = responseData.response.user
                    console.log(user);

                    this.setState({
                        country: user.country,

                        firstName: user.firstName,
                        lastName: user.lastName,
                        mobileNo: user.mobileNo,
                        description: user.description || '--',
                        location: user.selectedLocation,
                        countryCode: user.ccode || '--',
                        dob: user.birthDate != '' && user.birthDate != undefined ? moment(user.birthDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '--',
                        age: user.age || '--',
                        nickname: user.NickName || '--',
                        profession: user.Profession || '--',
                        oterProfession: user.OtherProfession || '--',
                        area: user.area || '--',
                        pincode: user.postal_code || '--',
                        street_address: user.street_address,

                        city: user.city || '--',
                        district: user.district || '--',
                        state: user.state || '--',
                        country: user.country || '--',
                        village: user.village || '--'

                    })

                    this.onGetLocationsList();
                    this.setState({ loading: false })
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false })
                }

            }).catch(error => {
                // // console.log("err", error);
                // alert(
                //     'Something went wrong, Please try again after sometime.'
                // )
                this.setState({ loading: false });
            }).finally(final => {
                // this.recaptchaRef.current.reset();
                // return this.setState({ isInvitingUser: false })
            });
    }

    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }




    render() {
        var path = window.location.origin

        // // // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}

                        <div className="container">
                            <div className="form-group ">
                                <Link to={'/'+this.state.Type} className="btn btn-danger btn-sm h5 m-3  mr-2 float-right" >
                                    <span className="menu-text" style={{ color: 'white' }}>Back to List</span>
                                </Link>
                                <br />
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">

                                        <div className="card-body row" style={{ paddingTop: 10 }}>


                                            <div className='col-md-8 border-right-desktop'>


                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Mobile Number / મોબાઇલ નંબર:</label><br />
                                                            <b>{this.state.ccode} {this.state.mobileNo}</b>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>First Name / નામ:</label><br />
                                                            <b>{this.state.firstName} </b>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Last Name / અટક: </label><br />
                                                            <b>{this.state.lastName} </b>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>Nick Name / ઉપનામ (Optional):</label><br />
                                                            <b> {this.state.NickName} </b>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>

                                                    <div className='col-md-6'>

                                                        <div className="form-group" >
                                                            <label>Profession / વ્યવસાય (Optional):</label><br />
                                                            <select disabled="true" className="form-control form-select-solid" >

                                                                <option value="" selected>Select Profession</option>
                                                                <option key="1" value="State govt. Job">State govt. Job</option>
                                                                <option key="2" value="Central govt. Job">Central govt. Job</option>
                                                                <option key="3" value="Doctor">Doctor</option>
                                                                <option key="4" value="Advocate">Advocate</option>
                                                                <option key="5" value="Engineer">Engineer</option>
                                                                <option key="6" value="Business">Business</option>
                                                                <option key="7" value="Retired">Retired</option>
                                                                <option key="8" value="Teacher">Teacher</option>
                                                                <option key="9" value="Farmer">Farmer</option>
                                                                <option key="10" value="Homemaker ( housewife)">Homemaker ( housewife)</option>
                                                                <option key="11" value="Others">Others</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {this.state.Profession == "Others" &&
                                                        <div className='col-md-6' >
                                                            <div className="form-group">
                                                                <label>Other Profession / અન્ય વ્યવસાય:</label><br />
                                                                <b>{this.state.OtherProfession}</b>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Country / દેશ:</label><br />
                                                            <b>{this.state.country}</b>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>State / રાજ્ય:</label><br />
                                                            <b>{this.state.state}</b>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>District / જિલ્લો:</label><br />
                                                            <b>{this.state.district}</b>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City / શહેર:</label><br />
                                                            <b>{this.state.city}</b>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 text-center'>
                                                        <div className="form-group mt-8">
                                                            <b>-OR-</b>
                                                        </div>

                                                    </div>

                                                    <div className="col-md-3" >
                                                        <div className="form-group">
                                                            <label>Village / ગામ:</label><br />
                                                            <b>{this.state.village}</b>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-4" >
                                                        <div className="form-group">
                                                            <label>Area / વિસ્તાર (Optional): </label><br />
                                                            <b>{this.state.area}</b>

                                                        </div>
                                                    </div>

                                                </div>



                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Birthdate / જન્મતારીખ (Optional):</label><br />
                                                            <b>{this.state.dob}</b>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 text-center'>
                                                        <div className="form-group mt-8">
                                                            <b>-OR-</b>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>Age(Years) / ઉંમર(વર્ષ) (Optional):</label><br />
                                                            <b>{this.state.age}</b>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Remarks / નૉૅધ (Optional):</label><br />
                                                            <b>{this.state.description}</b>

                                                        </div>
                                                    </div>
                                                </div>





                                            </div>


                                            <div className='col-md-4'>


                                                <div className="col-md-12" style={{ marginTop: 0 }}>
                                                    <div className="row form-group" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 0 }}>
                                                        <label>Location:</label>
                                                        <select disabled={this.state.enableLocationChange} className="form-control form-select-solid" style={{ backgroundColor: '#F3F6F9', color: this.state.enableLocationChange ? '#b7b7c4' : '#3F4254', }} aria-label="Select Location" >

                                                            {/* <option value="">Select Location</option> */}
                                                            {
                                                                this.state.Locations.map((location) => {
                                                                    return (<option selected={this.state.selectedLocation == location.place_id} value={location.place_id}>{location.name}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <br />
                                                    <div className="row border-bottom" style={{ justifyContent: 'center', marginBottom: 0 }}>
                                                        <div className="col-md-8" style={{ marginTop: 10 }}>
                                                            <div className='qr-full' style={{ height: "auto", margin: "0 auto", maxWidth: '100%', width: "100%" }}>
                                                                <QRCode
                                                                    size={100}
                                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                    value={`${path}/register/${this.state.selectedLocation}`}
                                                                    viewBox={`0 0 156 156`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(UsersDetails);
