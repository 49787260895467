import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthController from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import SubHeader from '../Components/SubHeader';
import Header from '../Include/Header';

class SideBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ischeckAuth: false,
			user: {},
			role: "",
			token: null,
			OrganizationID: '',
			selectedMenu: '/',
			current_url: '',
			height: 500
		}

	}

	async componentDidMount() {
		await this.getUserCred();
		let current_url = window.location.pathname.replace("/", "");
		await this.setState({ selectedMenu: current_url ? current_url : '/', current_url })
		// console.log("selectedMenu: ", window.location, current_url)
		this.setState({ height: window.screen.height - 230 })
	}
	onChangeMenu = async (tab) => {

		return this.setState({ selectedMenu: tab, current_url: '' });
	}

	async UNSAFE_componentWillReceiveProps() {
		let current_url = window.location.pathname.replace("#/", "");
		this.setState({ selectedMenu: current_url ? current_url : '/', current_url })
		this.setState({ height: window.screen.height - 230 })
	}

	/**
	 * @param null
	 * @description This function get user credintial from localStorage and store in state
	 */
	getUserCred = async () => {
		let user = await localStorage.getItem('user');
		let token = await localStorage.getItem('token');
		user = JSON.parse(user);

		let mode = localStorage.getItem("mode");
		let role = localStorage.getItem("role") || "";
		console.log(role)
		const hasTIP_MODE = mode === Constants.TIP_MODE;
		// console.log("mode: ", mode, hasTIP_MODE)

		let OrganizationID = user.OrganizationID

		if (!role) {
			alert("Session Expired, Please Login");
			AuthController.Logout()
		}
		//role="EventAdmin"
		if (!token) {
			this.setState({ loading: false });
			// Actions.Auth({ logout: true });
		} else {
			await this.setState({ user, token, role, OrganizationID, hasTIP_MODE });
		}
	}

	render() {
		return (
			<>
				<div className="aside aside-left aside-fixed d-flex flex-column flex-row-auto" style={{ backgroundColor: '#bd2e32' }} id="kt_aside">
					{/* <div className="brand flex-column-auto" id="kt_brand">
						<Link to={'/'} className="brand-logo">
							<img alt="Logo" src="/assets/logo_ABC_simple_white.png" style={{ width: '10rem' }} />
						</Link>
						<button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
							<span className="svg-icon svg-icon svg-icon-xl">
								<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" strokeWidth="1" fill="#bd2e32" fillRule="evenodd">
										<polygon points="0 0 24 0 24 24 0 24" />
										<path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fillRule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)" />
										<path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)" />
									</g>
								</svg>
							</span>
						</button>
					</div> */}
					<div className="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
						<div id="kt_aside_menu" className="aside-menu my-4" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
							<div style={{ height: '95vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexWrap: "wrap" }}>
								<ul className="menu-nav">
									
										<li className={`menu-item ${this.state.selectedMenu === '/' ? 'menu-item-active' : ''}`} aria-haspopup="true">
											<Link to={'/'} className="menu-link" onClick={() => { this.onChangeMenu('/') }}>
												<span className="svg-icon menu-icon fa fa-address-card" style={{ color: 'white' }}>
												</span>
												<span className="menu-text" style={{ color: 'white' }}>Dashboard</span>
											</Link>
										</li>
									{this.state.role == "MEMBER" &&
										<li className={`menu-item ${this.state.selectedMenu === '/Users' ? 'menu-item-active' : ''}`} aria-haspopup="true">
											<Link to={'/Users'} className="menu-link" onClick={() => { this.onChangeMenu('/Users') }}>
												<span className="svg-icon menu-icon fas fa-user" style={{ color: 'white' }}>
												</span>
												<span className="menu-text" style={{ color: 'white' }}>User</span>
											</Link>
										</li>
									}
									{this.state.role == "MEMBER" &&
										<li className={`menu-item ${this.state.selectedMenu === '/sangathan-list' ? 'menu-item-active' : ''}`} aria-haspopup="true">
											<Link to={'/sangathan-list'} className="menu-link" onClick={() => { this.onChangeMenu('/sangathan-list') }}>
												<span className="svg-icon menu-icon fas fa-layer-group" style={{ color: 'white' }}>
												</span>
												<span className="menu-text" style={{ color: 'white' }}>Sangathan</span>
											</Link>
										</li>
									}{(this.state.role == "EventAdmin" || this.state.role == "MEMBER") &&
										<li className={`menu-item ${this.state.selectedMenu === '/EventList' ? 'menu-item-active' : ''}`} aria-haspopup="true">
											<Link to={'/EventList'} className="menu-link" onClick={() => { this.onChangeMenu('/EventList') }}>
												<span className="svg-icon menu-icon fas fa-calendar" style={{ color: 'white' }}>
												</span>
												<span className="menu-text" style={{ color: 'white' }}>Event</span>
											</Link>
										</li>
									}{this.state.role == "MEMBER" &&
										<li className={`menu-item ${this.state.selectedMenu === '/LocationList' ? 'menu-item-active' : ''}`} aria-haspopup="true">
											<Link to={'/LocationList'} className="menu-link" onClick={() => { this.onChangeMenu('/LocationList') }}>
												<span className="svg-icon menu-icon fas fa-map-marker" style={{ color: 'white' }}>
												</span>
												<span className="menu-text" style={{ color: 'white' }}>Location</span>
											</Link>
										</li>
									}
									{/* <li className={`menu-item ${this.state.selectedMenu === '/Event' ? 'menu-item-active' : ''}`} aria-haspopup="true">
										<Link to={'/Event'} className="menu-link" onClick={() => { this.onChangeMenu('/Event') }}>
											<span className="svg-icon menu-icon fas fa-layer-group" style={{ color : 'white'}}>
											</span>
											<span className="menu-text" style={{ color : 'white' }}>Event Add</span>
										</Link>
									</li> */}
									{/* <li className={`menu-item ${this.state.selectedMenu === '/Event-Register' ? 'menu-item-active' : ''}`} aria-haspopup="true">
										<Link to={'/Event-Register'} className="menu-link" onClick={() => { this.onChangeMenu('/Event-Register') }}>
											<span className="svg-icon menu-icon fas fa-layer-group" style={{ color : 'white'}}>
											</span>
											<span className="menu-text" style={{ color : 'white' }}>EventRegister</span>
										</Link>
									</li> */}
									<li className={`menu-item ${this.state.selectedMenu === 'Logout' ? 'menu-item-active' : ''}`} aria-haspopup="true">
										<div className="menu-link" onClick={() => { this.onChangeMenu('Logout'); AuthController.Logout() }}>
											<span className="svg-icon menu-icon fas fa-sign-out-alt" style={{ color: 'white' }}>
											</span>
											<span className="menu-text" style={{ color: 'white' }}>Logout</span>
										</div>
									</li>
								</ul>
								<div class="compony">
									<div class="powerBy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 10, bottom: 0 }}>
										<h6 style={{ color: '#fff', fontSize: '1.100rem' }}>Powered By</h6>
										<img src="/assets/images/Logo/xpertnest-logo-white.png" className="max-h-120px" alt="" height={30} style={{ marginLeft: 0, }} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Header />
				
				<SubHeader />
			</>
		);
	}
}

export default SideBar;
