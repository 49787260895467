import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import { Link, Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import axios from 'axios';
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { appendScript } from '../Utilities/appendScript';
import moment from 'moment';
import QRCode from "react-qr-code";
import withRouter from '../Utilities/withRouter'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const BootstrapInput = styled(TextField)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

class RegisterVisitor extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.recaptchaRef = React.createRef();
        this.googlePlaceInputRef = React.createRef();

        const { location } = props.params;
        // const location = match.params.location;

        let locationParam = ""
        let enableLocationChange = false

        if (location) {
            locationParam = location
            enableLocationChange = true
        }
        // // console.log("PARAMS LOCATION ",location,enableLocationChange)

        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            OrganizationID: Constants.defaultOrganizationId,
            Locations: [],
            RoleList: [],
            SangathanList: [],
            isRedirect: false,
            sangathanId: null,
            country_code: '+91',
            dropdownData: [],
            interestData: [
            ],
            ccode: "+91",
            city: "",
            mobileNo: "",
            Profession: "",
            OtherProfession: "",
            selectedLocation: locationParam,
            enableLocationChange: enableLocationChange,
            firstName: "",
            lastName: "",
            NickName: "",
            description: "",
            birthDate: "",
            age: "",
            countries: [],
            stateList: [],
            districtList: [],
            CityList: [],
            VillageList: [],

            selectedCity: "",
            selectedCountry: "",
            selectedVillage: "",
            selectedDistrict: "",
            selectedState: "",

            cityCode: "",
            districtCode: "",
            stateCode: "",
            countryDataCode: "",
            villageCode: "",

            selectedUserInterest: [],
            selectedSangathan: {
                label: null,
                name: null,
                _id: null,
                options: [],
            },
            token: 'Bearer SECRET',
            city: "",
            district: "",
            state: "",
            country: "IN",
            village: "",
            postal_code: "",
            area: "",
            areas:[],
            street_address: "",
            addressComponent: null,
            defaultAddress: "",
            isNumberVerified: false,
            Pincode: "",
            optionalFieldAllow: true,
            info: { type: "info", msg: "" }
        };
    }

    async componentDidMount() {

        await appendScript('/assets/js/pages/crud/file-upload/image-input.js');




        await this.onGetLocationsList();


        // this._getPrepareCountryListCall()
        // this._getInterestData();

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    _getInterestData = () => {

        let urlPath = "DynamicPage/interests";

        // console.log("List Sangathan =>", Constants.url + urlPath)

        // console.log("TOKEN ",token)
        // return 

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {

                    this.state.interestData = responseData.response;


                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    console.log("Something went wrong")
                    this.setState({ loading: false })
                } else {
                    console.log("Something went wrong")
                    this.setState({ loading: false })
                }
            }).catch(error => {
                console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });

    }
    selectInerest = (e) => {
        console.log(e)
        if (this.state.selectedUserInterest.filter(s => s == e).length > 0) {
            this.state.selectedUserInterest = this.state.selectedUserInterest.filter(s => s != e)
        } else {
            this.state.selectedUserInterest.push(e);
        }
        console.log(this.state.selectedUserInterest)
    }

    _getPrepareCountryListCall = () => {

        // // console.log(`${Constants.THIRD_PARTY_API_URL}getaccesstoken/`,)
        axios({
            url: `${Constants.THIRD_PARTY_API_URL}getaccesstoken/`,
            method: 'GET',
            headers: {
                "api-token": Constants.THIRD_PARTY_TOKEN,
                "user-email": "ashish.gajjar@xpertnest.com",
                "Accept": "application/json"
            }
        })
            .then((response) => {
                const responseData = (response.data || {});
                // // console.log("responseData =>",JSON.stringify(response))
                if (response?.status == '200') {

                    this.setState({ third_party_token: responseData.auth_token })
                    this._getCountryList(responseData.auth_token)

                    this.setState({ selectedCity: "", selectedState: "", selectedCountry: "", stateList: [], countries: [], CityList: [] })
                }

            }).catch((error) => {
                // // console.log('error', error)
            });

    }

    _getCountryList = (token) => {

        // // console.log(`${token} - ${Constants.THIRD_PARTY_API_URL}countries/`,)
        axios({
            url: `${Constants.THIRD_PARTY_API_URL}countries/`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + token,
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then((response) => {
                const responseData = (response.data || {});
                if (response?.status == '200' && responseData.length > 0) {
                    const CountryList = responseData.map((data, index) => {
                        // return data.country_name
                        return { ...data, label: data.country_name }
                        // return { ...data, Name: data.country_short_name + " - " + data.country_name, countryCode: data.country_phone_code, Value: data.country_phone_code }
                    })
                    if (this.state.selectedCountry != '' || this.state.selectedCountry != null) {
                        this.state.countryDataCode = this.state.countries.filter(s => s.label == this.state.selectedCountry)[0].Code
                        console.log(this.state.countryDataCode)
                        this.onGetStateList(this.state.countryDataCode);
                    }
                    // // console.log("responseData =>", JSON.stringify(CountryList))
                    this.setState({ countries: CountryList })
                }
            }).catch((error) => {
                // // console.log('erroR', error)
            });

    }

    onSelectCountry = async (item) => {
        await this.setState({ selectedCountry: item })
        // // // console.log("item====>", item.country_name)
        let StateName = item

        axios({
            url: `${Constants.THIRD_PARTY_API_URL}states/${StateName}`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + this.state.third_party_token,
                "Accept": "application/json"
            }
        })
            .then((response) => {
                // // console.log(response.status)
                const responseData = (response.data || {});
                if (response?.status == '200' && responseData.length > 0) {
                    const StateList = responseData.map((data, index) => {
                        // return data.state_name
                        return { ...data, label: data.state_name }
                        // return { ...data, Name: data.state_name, Value: data.state_name }
                    })
                    this.setState({ stateList: StateList, CityList: [], selectedState: "", selectedCity: '' })
                    // // // console.log("StateList===>", StateList)

                }
                // // // console.log("responseData =>", responseData)
            }).catch((error) => {
                // // console.log('error', error)
            });

    }
    onSelectState = async (item) => {
        await this.setState({ selectedState: item })

        let CityName = item
        // // console.log("CityName===>", CityName)

        axios({
            url: `${Constants.THIRD_PARTY_API_URL}cities/${CityName}`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + this.state.third_party_token,
                "Accept": "application/json"
            }
        })
            .then((response) => {
                // // console.log(response.status)
                const responseData = (response.data || {});
                if (response?.status == '200' && responseData.length > 0) {
                    const cityList = responseData.map((data, index) => {

                        // return data.city_name
                        return { ...data, label: data.city_name }
                        // return { ...data, Name: data.city_name, Value: data.city_name }
                    })
                    this.setState({ CityList: cityList })
                    // // console.log("CityList===>", cityList)

                }
                // // console.log("responseData =>", responseData)
            }).catch((error) => {
                // // console.log('error', error)
            });


    }

    onChangePostcode = (e) => {
        console.log(e)
        //let urlPath = "https://api.postalpincode.in/pincode/" + e;

        if (e != null && e != "") {
            let urlPath = "auth/PostalCode/" + e;

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);

                    if (responseData.status) {

                        if (responseData.response[0].PostOffice != null && responseData.response[0].PostOffice.length > 0) {
                            var data = responseData.response[0].PostOffice[0];
                            this.state.selectedCountry = data.Country;
                            this.state.selectedState = data.State;
                            this.state.selectedDistrict = data.District;
                            this.state.selectedCity = data.Block;
                            this.state.street_address = data.Country + ", " + data.State + ", " + data.District + ", " + data.Block
                            let areas = []
                            responseData.response[0].PostOffice.map((c, i) => {
                                areas.push({ label: c.Name, id: c.Name, Code: c.Name })
                            })
                            console.log(data)
                            this.setState({ areas: areas })

                            //this.onGetCountryList();
                        } else {
                            this.setState({ selectedCountry: "", selectedState: "", selectedDistrict: "", selectedCity: "",area:"",areas:[] })
                            
                            let errors = {};
                            errors.Pincode = "Please enter correct Pincode";
                            this.setState({ errors: errors });
                            
                        }
                    } {

                    }

                    this.setState({ loading: false });

                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ selectedCountry: "", selectedState: "", selectedDistrict: "", selectedCity: "" })
        }
    }


    onGetLocationsList = async () => {
        await this.setState({ loading: true })

        const { token, user, searchText } = this.state;

        let state = user
        let urlPath = "DynamicPage/entry-locations/"

        // // console.log("List Role =>", Constants.url + urlPath)

        // // // console.log("TOKEN ",token)
        // return 

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;
                    let RoleList = [];

                    const LocationsList = data.filter(item => item.status === "true").map((item) => {
                        return { ...item, Name: item.name, id: item.place_id, Value: item.name }
                    })
                    // // // console.log("DL ",LocationsList)

                    if (LocationsList.length > 0 && !this.state.enableLocationChange) {
                        // setSelectedLocation(LocationsList[0])
                        this.setState({ selectedLocation: LocationsList[0].place_id, refreshInput: true })
                    }

                    this.setState({ Locations: LocationsList, refreshInput: false })
                    await this.onGetCountryList();
                    // this.setState({ Locations : LocationsList})

                    //   await this.setState({ RoleList, loading: true })

                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                } else {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }

    onGetCountryList = async () => {
        await this.setState({ loading: true })

        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    console.log(data)
                    this.setState({ countries: countries, refreshInput: false })

                    if (this.state.selectedCountry != '' && this.state.selectedCountry != null) {
                        this.state.stateCode = countries.filter(s => s.label == this.state.selectedCountry)[0].Code
                        console.log(this.state.stateCode)
                        this.onGetStateList(this.state.stateCode);
                    } else {
                        this.state.selectedCity = ""

                        this.state.selectedDistrict = ""
                        this.state.selectedState = ""
                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetStateList = async (data) => {
        console.log(data)
        this.state.countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        console.log(stateList)
                        this.setState({ stateList: stateList, refreshInput: false })

                        if (this.state.selectedState != '' && this.state.selectedState != null) {
                            this.state.stateCode = stateList.filter(s => s.label == this.state.selectedState)[0].Code
                            console.log(this.state.stateCode)
                            this.onGetDistrictList(this.state.stateCode);
                        } else {
                            this.state.selectedState = ""
                            this.state.selectedDistrict = ""
                            this.state.selectedCity = ""

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    onGetDistrictList = async (data) => {
        this.state.stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    this.setState({ districtList: districtList, refreshInput: false })

                    if (this.state.selectedDistrict != '' && this.state.selectedDistrict != null) {
                        this.state.districtCode = districtList.filter(s => s.label == this.state.selectedDistrict)[0].Code
                        console.log(this.state.districtCode)
                        this.onGetCitiesList(this.state.districtCode);
                    } else {
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetCitiesList = async (data) => {
        this.state.districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []
                    this.state.selectedCity = ""
                    data.map((c, i) => {
                        cityList.push({ label: c.name, id: c._id, Code: c.id })
                    })

                    this.setState({ cityList: cityList, refreshInput: false })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    // onGetVillageList = async (data) => {
    //     this.state.cityCode=data
    //     let urlPath = "auth/VillageList/" + data

    //     fetch(Constants.VUF_APP_API + urlPath, {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         // body: Body,
    //     }).then((response) => response.json())
    //         .then(async (responseData) => {
    //             // // // console.log("RES ",responseData)
    //             if (responseData.status ) {
    //                 var data = responseData.response;
    //                 let VillageList = []
    //                 this.state.selectedVillage= ""
    //                 data.map((c,i) => {
    //                     VillageList.push({ label: c.VillageName, id: c._id,Code:c.VillageCode })
    //                 })

    //                 this.setState({ VillageList: VillageList, refreshInput: false })



    //             }
    //         }).catch(error => {
    //             // // console.log("err", error);

    //         }).finally(final => {
    //             return this.setState({ loading: false })
    //         });
    // }
    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = (event) => {
        let errors = {};
        let formIsValid = true;

        if (this.state.firstName?.trim() === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        } else if (!/^[a-zA-Z ]*$/.test(this.state.firstName)) {
            formIsValid = false;
            errors.firstName = "Please enter a valid value containing only english letters";
        }

        if (this.state.lastName?.trim() === "") {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        } else if (!/^[a-zA-Z ]*$/.test(this.state.lastName)) {
            formIsValid = false;
            errors.lastName = "Please enter a valid value containing only english letters";
        }

        if (this.state.selectedLocation === "") {
            formIsValid = false;
            errors.selectedLocation = "Please selected location";
        }

        if (this.state.ccode?.trim() === "") {
            formIsValid = false;
            errors.ccode = "Please enter country code";
        }
        else if (!/^[+]\d{0,5}$/.test(this.state.ccode)) {
            formIsValid = false;
            errors.ccode = "Please enter a valid value";
        }
        // if (this.state.street_address?.trim() === "") {
        //     formIsValid = false;
        //     errors.address = "Please select address";
        // }

        if ((this.state.selectedCity == '' || this.state.selectedCity == null || this.state.selectedCity == undefined) && (this.state.selectedVillage == '' || this.state.selectedVillage == null || this.state.selectedVillage == undefined)) {
            formIsValid = false;
            errors.selectedCity = "Please enter City or Village";
        }

        if ((this.state.age == '' || this.state.age == null || this.state.age == undefined) && (this.state.birthDate == '' || this.state.birthDate == null || this.state.birthDate == undefined)) {
            formIsValid = false;
            errors.birthDate = "Please enter birthdate or age";
        }
        if (this.state.age != '') {
            if (!/^\d{1,3}$/.test(this.state.age)) {
                formIsValid = false;
                errors.age = "Please enter a valid age";
            }
        }

        const regexPin = /^\d{0,5}$/; 
        if(this.state.Pincode?.trim()===""){

            formIsValid = false;
            errors.Pincode = "Please enter Pincode";
            
        }else if (regexPin.test(this.state.Pincode)) {
            errors.Pincode = "Please enter 6 digit Pincode";
        }
        if(this.state.selectedCity?.trim() === ""){
            formIsValid = false;
            errors.Pincode = "Please enter correct Pincode";
        }
        // if( /^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$/.test(this.state.birthDate)){
        //     formIsValid = false;
        //     errors.birthDate = "Please enter a valid birthDate";
        // }

        // if (this.state.state?.trim() === "") {
        //     formIsValid = false;
        //     errors.selectedState = "Please select state";
        // }
        // if (this.state.city?.trim() === "") {
        //     formIsValid = false;
        //     errors.city = "Please select city";
        // }


        if (this.state.area?.trim() && !/^[a-zA-Z ]*$/.test(this.state.area)) {
            formIsValid = false;
            errors.area = "Please enter a valid value containing only english letters";
        }

        if (this.state.description?.trim() && !/^[a-zA-Z ]*$/.test(this.state.description)) {
            formIsValid = false;
            errors.description = "Please enter a valid value containing only english letters";
        }

        if (this.state.mobileNo?.trim() === "") {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile number";
        }




        this.setState({ errors: errors });

        return formIsValid;
    }


    reCaptchaCheck = async (e) => {
        if (!this.handleValidation()) {
            // // console.log("FAILED")
            return false
        }

        // let r = this.recaptchaRef.current.execute();
    }

    onUserInvite = async (e) => {
        // e.preventDefault()

        // this.recaptchaRef.current.executeAsync().then(value => {
        //     // // console.log("executeAsync promise - Captcha value:", value);
        // });

        if (!this.handleValidation()) {
            // // console.log("FAILED")
            return false
        }


        this.setState({ loading: true })
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { email, ccode, mobileNo, selectedLocation, firstName, OtherProfession, Pincode, Profession, description, lastName, NickName, age, token, addressComponent,
            street_address, postal_code, area, selectedCity, selectedState, selectedCountry, selectedDistrict, selectedUserInterest, selectedVillage
            , cityCode, districtCode, stateCode, countryDataCode } = this.state;
        const Body = JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "mobileNo": mobileNo.trim(),
            "remarks": description.trim(),
            "location": selectedLocation,
            'countryCode': ccode,
            'Pincode': Pincode,
            'dob': this.state.birthDate != '' ? moment(this.state.birthDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
            'InterestIds': selectedUserInterest,
            'age': age || '',
            'nickname': NickName || '',

            'profession': Profession || '',
            'otherProfession': OtherProfession || '',
            'area': area || '',
            'pincode': postal_code || '',
            'addressComponent': addressComponent || {},
            'street_address': `${street_address}`,
            'client': Constants.AUTH_APP_NAME,
            'client_secret': Constants.AUTH_SECRET,
            'joinedFrom': "VISITOR_REGISTRATION_FORM_APP",
            'city': selectedCity || '',
            'district': selectedDistrict || '',
            'state': selectedState || '',
            'country': selectedCountry || '',
            'village': selectedVillage || '',

            'cityCode': cityCode || '',
            'districtCode': districtCode || '',
            'stateCode': stateCode || '',
            'countryDataCode': countryDataCode || '',


        })

        let urlPath = "create-visitor";

        fetch(Constants.AUTH_URL + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                // // console.log(responseData);

                if (responseData.status && 'response' in responseData) {

                    this.setState({ mobileNo: "", firstName: "", NickName: "", age: "", lastName: "", description: "", loading: false, birthDate: "", selectedVillage: '', defaultAddress: this.getFormattedAddressComponentData(addressComponent || null), refreshInput: true, info: { type: "info", msg: "" } });

                    setTimeout(() => {
                        this.setState({ refreshInput: false })
                    }, 100)

                    alert(
                        'Registered!\n\nVisitor entry registered successfully.',
                    )


                } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                    alert(
                        'Sorry! \n\n ' + responseData.error)
                    this.setState({ loading: false })
                } else if (typeof responseData.error === "object") {
                    alert(
                        'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false })
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false })
                }

            }).catch(error => {
                // // console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
                // this.recaptchaRef.current.reset();
                // return this.setState({ isInvitingUser: false })
            });
    }


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }
    formatDate = (date) => {
        if (!date) return "";
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };
    onCaptchaChange = (value) => {

        // // console.log("onChange prop - Captcha value:", value);
        if (value === null || value === undefined || value === "") {
            // Handle reCAPTCHA verification failure here
            // // console.log("reCAPTCHA verification failed!");
            //   this.setState({ captchaError: true });
        } else {
            // // console.log("reCAPTCHA verification success!");
            this.onUserInvite()
            //   this.setState({ value, captchaError: false });
        }
        if (value === null) {
            alert(
                'Something went wrong, Please try again after sometime.',
            )
            this.recaptchaRef.current.reset();
            // // console.log("reCAPTCHA verification expired!");
            // this.setState({ expired: "true" });
        }
        // // // console.log("R ",r)
    }


    numberCheckAPI = async () => {

        // this.setState({ loading : true })
        this.setState({ info: { type: "info", msg: "Looking for user, wait..." }, errors: {}, loading: false });
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { ccode, mobileNo } = this.state;
        const Body = JSON.stringify({
            'countryCode': ccode,
            "mobileNo": mobileNo.trim(),
            'client': Constants.AUTH_APP_NAME,
            'client_secret': Constants.AUTH_SECRET
        })

        let urlPath = "check-mobile-registered";

        // console.log(Constants.AUTH_URL + urlPath, token, Body)
        // this.setState({ loading : false })
        // return

        fetch(Constants.AUTH_URL + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData);

                if (responseData.status && responseData.auth) {


                    let errors = {}

                    errors.mobileNo = "User already registered.";

                    this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });




                } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                    // alert(
                    //     'Sorry! \n\n '+responseData.error)
                    //     this.setState({ loading : false })

                    // let valids = {}

                    // valids.mobileNo = "User is valid";

                    this.setState({ info: { type: "success", msg: "User is valid" }, errors: {}, isNumberVerified: true, loading: false });


                } else if (typeof responseData.error === "object") {
                    alert(
                        'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading : false })
                    let errors = {}

                    errors.mobileNo = "Something went wrong.";

                    this.setState({ errors: errors, isNumberVerified: false, loading: false });
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )

                    let errors = {}

                    errors.mobileNo = "Something went wrong.";

                    this.setState({ errors: errors, isNumberVerified: false, loading: false });
                }

            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                let errors = {}

                errors.mobileNo = "Something went wrong.";

                this.setState({ errors: errors, isNumberVerified: false, loading: false });

            }).finally(final => {

            });
    }



    checkMobileNumberIsRegistrered = (event) => {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue) && inputValue.length == 10) {

            this.numberCheckAPI()

        } else {
            this.setState({ isNumberVerified: false })
        }

    };
    handleNumberChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue)) {
            // Valid input: 10 digits without spaces
            // Handle the input or set the state accordingly
            // For example:
            // console.log("N",inputValue,inputValue.length)
            this.setState({ mobileNo: inputValue });
        } else {
            // // // console.log("E",inputValue)
            // Invalid input
            // You can show an error message or prevent further action
        }
    };

    setAutocompleteValue = (value) => {
        if (this.googlePlaceInputRef.current) {
            // Set the value of the input field
            this.googlePlaceInputRef.current.value = value;

            // Manually trigger the input event
            const event = new Event('input', { bubbles: true });
            this.googlePlaceInputRef.current.dispatchEvent(event);
        }
    }

    cleanAddress = () => {
        this.setState({ city: "" })
        this.setState({ district: "" })
        this.setState({ state: "" })
        this.setState({ country: "" })
        this.setState({ street_address: "", defaultAddress: "", addressComponent: null, refreshInput: true });
        setTimeout(() => {
            this.setState({ refreshInput: false })
        }, 100)
    }

    /**
    * @description this function will get the input from google place autocomplete and extract city, state and country
    * @param {*} placeDetails
    * @param {*} AddressDetails
    */
  

    onSelectLocation = (e) => {
        let location = e.target.value

        const foundItem = this.state.Locations.find(item => item.place_id == location);
        // console.log("LOC ",foundItem,this.getFormattedAddressComponentData(foundItem?.addressComponent || null) )
        this.state.optionalFieldAllow = foundItem?.optionalFieldAllow;
        this.setState({ selectedLocation: location})

        setTimeout(() => {
            this.setState({ refreshInput: false })
        }, 100)
        // setResult(foundItem || null);
    }
    formatDate = (date) => {
        if (!date);
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };
    render() {


        const { interestData } = this.state;
        var path = window.location.origin

        // // // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container" style={{ marginTop: 30 }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        this.state.ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.ResErrors.error}
                                        </div>
                                    }
                                    {
                                        this.state.successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMsg}
                                        </div>
                                    } */}
                                    <div className="card p-0 card-custom gutter-b example example-compact">
                                        <div className="card-header row" style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div className='col-md-8' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <img style={{ height: 100, width: 100 }} src={'/assets/UmiyaFoundationWhite.png'} />
                                            </div>
                                            <div className='col-md-4' style={{ display: 'flex', justifyContent: 'flex-start' }}>

                                                <div className="form-group" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 0 }}>
                                                    <label>Location:</label>
                                                    <select disabled={this.state.enableLocationChange} className="form-control form-select-solid" style={{ backgroundColor: '#F3F6F9', color: this.state.enableLocationChange ? '#b7b7c4' : '#3F4254', }} aria-label="Select Location" onChange={(event) => this.onSelectLocation(event)}>

                                                        {/* <option value="">Select Location</option> */}
                                                        {
                                                            this.state.Locations.map((location) => {
                                                                return (<option selected={this.state.selectedLocation == location.place_id} value={location.place_id}>{location.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-body row" style={{ paddingTop: 10 }}>



                                            <div className='col-md-8 pr-2 pl-0 border-right-desktop'>

                                                <div className='col-md-12' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <label style={{ textAlign: 'center', width: '100%', fontSize: 23, fontWeight: '700', color: '#bc2f30', marginBottom: 10 }}>VUF Registration</label>
                                                </div>



                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                        <div className='row'>
                                                            <div className='col-md-2 col-sm-2 col-xs-2' style={{ paddingRight: 0, width: '20%' }}>
                                                                <input type="text" name="ccode" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="+91" onChange={(event) => { Functions.onChange(event, this); this.handleValidation(event) }} value={this.state.ccode} />
                                                                <span className="form-text text-danger">{this.state.errors?.ccode}</span>
                                                            </div>
                                                            <div className='col-md-10 col-sm-10 col-xs-10' style={{ width: '80%' }}>
                                                                <input type="text" name="mobileNo" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Mobile number" onBlur={(event) => this.checkMobileNumberIsRegistrered(event)} onChange={(event) => this.handleNumberChange(event)} value={this.state.mobileNo} />
                                                                <span className="form-text text-danger">{this.state.errors?.mobileNo}</span>
                                                                <span className={`form-text text-${this.state.info.type}`}>{this.state.info?.msg}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label>First Name / નામ:</label>
                                                        <input type="text" disabled={!this.state.isNumberVerified} name="firstName" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter first name" onChange={(event) => Functions.onChange(event, this)} value={this.state.firstName} />
                                                        <span className="form-text text-danger">{this.state.errors?.firstName}</span>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>Last Name / અટક: </label>
                                                        <input type="text" disabled={!this.state.isNumberVerified} name="lastName" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter last name" onChange={(event) => Functions.onChange(event, this)} value={this.state.lastName} />
                                                        <span className="form-text text-danger">{this.state.errors?.lastName}</span>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Nick Name / ઉપનામ (Optional):</label>
                                                        <input type="text" disabled={!this.state.isNumberVerified} name="NickName" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter Nick name" onChange={(event) => Functions.onChange(event, this)} value={this.state.NickName} />
                                                        <span className="form-text text-danger">{this.state.errors?.NickName}</span>
                                                    </div>
                                                </div>
                                                <div className='row'>

                                                    <div className='col-md-6'>

                                                        <label>Profession / વ્યવસાય (Optional):</label>
                                                        <select disabled={!this.state.isNumberVerified} onChange={(e) => { this.setState({ Profession: e.target.value, OtherProfession: "" }) }} className="form-control form-select-solid" >

                                                            <option value="" selected>Select Profession</option>
                                                            <option key="1" value="State govt. Job">State govt. Job</option>
                                                            <option key="2" value="Central govt. Job">Central govt. Job</option>
                                                            <option key="3" value="Doctor">Doctor</option>
                                                            <option key="4" value="Advocate">Advocate</option>
                                                            <option key="5" value="Engineer">Engineer</option>
                                                            <option key="6" value="Business">Business</option>
                                                            <option key="7" value="Retired">Retired</option>
                                                            <option key="8" value="Teacher">Teacher</option>
                                                            <option key="9" value="Farmer">Farmer</option>
                                                            <option key="10" value="Homemaker ( housewife)">Homemaker ( housewife)</option>
                                                            <option key="11" value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                    {this.state.Profession == "Others" &&
                                                        <div className='col-md-6' >
                                                            <label>Other Profession / અન્ય વ્યવસાય:</label>
                                                            <input type="text" name="OtherProfession" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter your profession" onChange={(event) => Functions.onChange(event, this)} value={this.state.OtherProfession} />
                                                            <span className="form-text text-danger">{this.state.errors?.OtherProfession}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                        <label>Pincode / પિનકોડ: </label>
                                                        <input type="text" name="Pincode"  disabled={!this.state.isNumberVerified} className="form-control form-control-solid" placeholder="Pincode" onBlur={(event) => {this.onChangePostcode(event.target.value)}} onChange={(event) => Functions.onChange(event, this)} value={this.state.Pincode} />
                                                        <span className="form-text text-danger">{this.state.errors?.Pincode}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Country / દેશ, State / રાજ્ય, District / જિલ્લો, City / શહેર </label>

                                                        <input type="text" name="street_address" disabled="true" className="form-control form-control-solid" value={this.state.street_address} />

                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>Area / વિસ્તાર (Optional): </label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={this.state.isNumberVerified && this.state.areas.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={this.state.area}
                                                            options={this.state.areas}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ area: v.label })

                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {
                                                                    this.setState({ area: "" })

                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <label>Country / દેશ:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={this.state.isNumberVerified && this.state.countries.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={this.state.selectedCountry}
                                                            options={this.state.countries}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedCountry: v.label })
                                                                    this.onGetStateList(v.Code)
                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {
                                                                    this.setState({ selectedState: "" })
                                                                    this.setState({ selectedDistrict: "" })
                                                                    this.setState({ selectedCity: "" })
                                                                    this.setState({ selectedCountry: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />



                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>State / રાજ્ય:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={this.state.isNumberVerified && this.state.stateList.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={this.state.selectedState}
                                                            options={this.state.stateList}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedState: v.label })
                                                                    this.onGetDistrictList(v.Code)

                                                                } else if (r == 'clear') {
                                                                    this.setState({ selectedDistrict: "" })
                                                                    this.setState({ selectedCity: "" })
                                                                    this.setState({ selectedState: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />


                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>District / જિલ્લો:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={this.state.isNumberVerified && this.state.districtList.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={this.state.selectedDistrict}
                                                            options={this.state.districtList}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedDistrict: v.label })
                                                                    this.onGetCitiesList(v.Code)
                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {
                                                                    this.setState({ selectedCity: "" })
                                                                    this.setState({ selectedDistrict: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />



                                                    </div> */}

                                                </div>
                                                <div className='row'>
                                                <div className='col-md-12'>
                                                    <label>Address / સરનામું (Optional):</label>
                                                    <textarea disabled={!this.state.enableInput} type="text" name="street_address" className="form-control form-control-solid" placeholder="Enter Address" onChange={(event) => Functions.onChange(event, this)}>
                                                        {this.state.street_address}
                                                    </textarea>
                                                    <span className="form-text text-danger">{this.state.errors?.street_address}</span>
                                                </div>
                                                </div>
                                                {/* <div className='row'>
                                                    <div className="col-md-4">
                                                        <label>City / શહેર:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={this.state.isNumberVerified && this.state.cityList != undefined && this.state.cityList.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            options={this.state.cityList}
                                                            //value={this.state.selectedCity}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedCity: v.label, cityCode: v.Code })
                                                                    // this.onGetVillageList(v.Code)
                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {

                                                                    this.setState({ selectedCity: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />
                                                        <span className="form-text text-danger">{this.state.errors?.selectedCity}</span>


                                                    </div>
                                                    <div className='col-md-1 text-center'>
                                                        <div className=" mt-8">
                                                            <b>-OR-</b>
                                                        </div>

                                                    </div>

                                                    <div className="col-md-3" >
                                                        <label>Village / ગામ:</label>
                                                        <input type="text" disabled={!this.state.isNumberVerified} name="selectedVillage" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter village" onChange={(event) => Functions.onChange(event, this)} value={this.state.selectedVillage} />
                                                        <span className="form-text text-danger">{this.state.errors?.selectedVillage}</span>
                                                    </div>

                                                    {this.state.optionalFieldAllow == true &&
                                                        <div className="col-md-4" >
                                                            <label>Area / વિસ્તાર (Optional): </label>
                                                            <input type="text" disabled={!this.state.isNumberVerified} name="area" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter area" onChange={(event) => Functions.onChange(event, this)} value={this.state.area} />
                                                            <span className="form-text text-danger">{this.state.errors?.area}</span>
                                                        </div>
                                                    }
                                                </div> */}

                                                {/* <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>Country / દેશ:</label>
                                                        
                                                                <Autocomplete
                                                                    disablePortal
                                                                    // disableClearable
                                                                    disabled={this.state.countries.length > 0 ? false : true}
                                                                    value={this.state.selectedCountry}
                                                                    style={{ width : '100%', padding : 0 }}
                                                                    id="combo-box-demo-country"
                                                                    options={this.state.countries}
                                                                    sx={{ width: 300 }}
                                                                    onChange={(e,v,r,d) => { 
                                                                        // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                        if(r == 'selectOption') {
                                                                            // this.setState({ selectedCity : v.label})
                                                                            this.onSelectCountry(v.label)
                                                                        } else if(r == 'clear') {
                                                                            this.setState({ selectedCountry : "" })
                                                                            this.setState({ selectedState : "" })
                                                                            this.setState({ selectedCity : "" })
                                                                        }
                                                                     }}
                                                                    // onSelect={(e) => { this.onSelectCountry(e.target.value) }}
                                                                    renderInput={(params) => <TextField {...params} className="form-control form-control-solid" label="" />}
                                                                />
                                                                
                                                            <span className="form-text text-danger">{this.state.errors?.selectedCountry}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>State / રાજ્ય:</label>
                                                        
                                                            <Autocomplete
                                                                disablePortal
                                                                // disableClearable
                                                                disabled={this.state.stateList.length > 0 ? false : true}
                                                                value={this.state.selectedState}
                                                                style={{ width : '100%', padding : 0 }}
                                                                id="combo-box-demo-state"
                                                                options={this.state.stateList}
                                                                sx={{ width: 300 }}
                                                                onChange={(e,v,r,d) => { 
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if(r == 'selectOption') {
                                                                        // this.setState({ selectedCity : v.label})
                                                                        this.onSelectState(v.label)
                                                                    } else if(r == 'clear') {
                                                                        this.setState({ selectedState : "" })
                                                                        this.setState({ selectedCity : "" })
                                                                    }
                                                                 }}
                                                                // onSelect={(e) => { this.onSelectState(e.target.value) }}
                                                                renderInput={(params) => <TextField {...params} className="form-control form-control-solid" label="" />}
                                                            />
                                                            <span className="form-text text-danger">{this.state.errors?.selectedState}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>City / શહેર:</label>
                                                        
                                                           
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.CityList.length > 0 ? false : true}
                                                                value={this.state.selectedCity}
                                                                style={{ width : '100%', padding : 0 }}
                                                                id="combo-box-demo-city"
                                                                options={this.state.CityList}
                                                                sx={{ width: 300 }}
                                                                onChange={(e,v,r,d) => { 
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if(r == 'selectOption') {
                                                                        this.setState({ selectedCity : v.label})
                                                                    } else if(r == 'clear') {
                                                                        this.setState({ selectedCity : "" })
                                                                    }
                                                                 }}
                                                                renderInput={(params) => <TextField {...params} className="form-control form-control-solid" label="" />}
                                                            />
                                                            <span className="form-text text-danger">{this.state.errors?.selectedCity}</span>
                                                        </div>
                                                    </div>

                                                </div> */}

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label>Birthdate / જન્મતારીખ (Optional):</label>

                                                        {/* <input type="date"  name="birthDate" className="form-control form-control-solid"  
                                                            placeholder="dd/mm/yyyy"  placeholderText="Enter birthDate  (Optional)"  
                                                            onChange={(event) => this.formatDate(event.target.value)} value={this.state.birthDate} /> */}

                                                        <DatePicker className="form-control py-4 px-8 " name="birthDate"
                                                            dateFormat="dd/MM/yyyy" placeholderText="dd / mm / yyyy"
                                                            selected={this.state.birthDate}
                                                            disabled={!this.state.isNumberVerified}
                                                            onChange={(event) => { this.setState({ birthDate: event }); this.handleValidation(event) }}
                                                            // onChange={(event) => Functions.onChange(event, this)} 
                                                            value={this.state.birthDate}
                                                        />
                                                        <span className="form-text text-danger">{this.state.errors?.birthDate}</span>
                                                    </div>
                                                    <div className='col-md-2 text-center'>
                                                        <div className=" mt-8">
                                                            <b>-OR-</b>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Age(Years) / ઉંમર(વર્ષ) (Optional):</label>

                                                        <input type="text" name="age" disabled={!this.state.isNumberVerified} className="form-control form-control-solid" placeholderText="Enter age  (Optional)" onChange={(event) => { this.setState({ age: event.target.value }); Functions.onChange(event, this); this.handleValidation(event) }} value={this.state.age} />
                                                        <span className="form-text text-danger">{this.state.errors?.age}</span>
                                                    </div>
                                                </div>


                                                {/* {this.state.optionalFieldAllow == true &&
                                                    <div className='row'>
                                                        <label className="ml-4">Interest / રસ (Optional):</label><br />
                                                        <div className='row col-md-12'>

                                                            {interestData.map((data, index) => (
                                                                <div class="col-md-4 mb-2">
                                                                    <div className="checkbox-inline ">
                                                                        <label className="checkbox m-0">
                                                                            <input type="checkbox" name="interest" onChange={(e) => { this.selectInerest(e.target.value) }} value={data._id} />
                                                                            <span  ></span>{data.name}</label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <br />
                                                    </div>
                                                }<br /> */}

                                                {this.state.optionalFieldAllow == true &&
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <label>Remarks / નૉૅધ (Optional):</label>
                                                            <textarea disabled={!this.state.isNumberVerified} type="text" name="description" className="form-control form-control-solid" placeholder="Enter remarks" onChange={(event) => Functions.onChange(event, this)}>
                                                                {this.state.description}
                                                            </textarea>
                                                            <span className="form-text text-danger">{this.state.errors?.description}</span>
                                                        </div>
                                                    </div>
                                                }

                                                {/* <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Remarks / નૉૅધ:</label>
                                                            <textarea disabled={!this.state.isNumberVerified} type="text" name="remarks" className="form-control form-control-solid" placeholder="Enter remarks (Optional)" onChange={(event) => Functions.onChange(event, this)}>
                                                                {this.state.remarks}
                                                            </textarea>
                                                            <span className="form-text text-danger">{this.state.errors?.remarks}</span>
                                                        </div>
                                                    </div>
                                                </div> */}



                                                <div className="card-footer" style={{ justifyContent: 'center', textAlign: 'center' }}>
                                                    {
                                                        this.state.btnLoading ?
                                                            <button type="button" className="btn btn-primary"><Spinner color={'#fff'} /></button>
                                                            :
                                                            <button type="button" disabled={!this.state.isNumberVerified} className="btn btn-primary" onClick={(event) => this.onUserInvite()}>Register Now</button>
                                                    }

                                                    {/* <Link to={'/ListBranch/' + this.state.OrganizationID} className="btn btn-secondary">Cancel</Link> */}
                                                </div>




                                            </div>


                                            <div className='col-md-4'>


                                                <div className="col-md-12" style={{ marginTop: 0 }}>
                                                    {!this.state.enableLocationChange && <label style={{ textAlign: 'center', width: '100%', fontSize: 22, fontWeight: '600' }}>Register QR</label>}
                                                    {!this.state.enableLocationChange &&
                                                        <div className="row border-bottom" style={{ justifyContent: 'center', marginBottom: 0 }}>
                                                            <div className="col-md-8" style={{ marginTop: 10 }}>
                                                                <div className='qr-full' style={{ height: "auto", margin: "0 auto", maxWidth: '100%', width: "100%" }}>
                                                                    {/* <label style={{ textAlign : 'center', width : '100%', fontSize : 14, fontWeight : '500' }}>Register QR</label> */}
                                                                    <QRCode
                                                                        size={100}
                                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                        value={`${path}/register/${this.state.selectedLocation}`}
                                                                        viewBox={`0 0 156 156`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>


                                            </div>
                                            {/* <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                size="invisible"
                                                sitekey="6Le5UzgpAAAAADrpPAkYdXobxOMc-99mN9DJqpZ3"
                                                onChange={this.onCaptchaChange}
                                                /> */}
                                        </div>
                                        {/* <div className="card-footer hide-mob" style={{ justifyContent : 'center', textAlign :'center'}}>
                                            {
                                                this.state.btnLoading ?
                                                    <button type="button" className="btn btn-primary mr-2"><Spinner color={'#fff'} /></button>
                                                    :
                                                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.reCaptchaCheck(event)}>Register Now</button>
                                            }
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(RegisterVisitor);
